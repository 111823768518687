import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeStyles, Paper, Toolbar, TextField, IconButton, Button, Typography,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ListIcon from '@material-ui/icons/ViewList';

import DevicesList from './DevicesList';
import Map from './map/Map';
import GeofenceEditMap from './map/GeofenceEditMap';
import GeofencesList from './GeofencesList';

import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';
import BottomMenu from './components/BottomMenu';
import { useTranslation } from './LocalizationProvider';
import PoiMap from './map/PoiMap';
import MapPadding from './map/MapPadding';
import { api_call, console_log, getToken } from './helpers/untils';
import { devicesActions, geofencesActions, layoutActions } from './store';
import PaypalCreditLogsModal from './settings/PaypalCreditLogsModal';
import { AuthorizedLayout } from './layout/AuthorizedLayout';
import Tour from 'reactour'
import { ButtonBase } from '@mui/material';
import { GEOFENCE_VIDEO_ID } from './config/constant';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 56,
    zIndex: 1300,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
    overflow: 'auto',
    paddingBottom: '48px'
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  searchTextBox: {
    position: 'relative',
    flex: 1,
    // paddingRight: '50px'
  },
  searchTextSpan: {
    position: 'absolute',
    right: "10px",
    top: "0px",
    bottom: "0px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  searchText: {
    paddingRight: '56px'
  },
  tourGeofenceMap: {
    top: '70px',
    right: 0,
    height: '75px',
    position: 'fixed',
    backgroundColor: 'transparent',
    width: '50px'
  },
  videoBtnBox: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '16px',
  }

}));

const GeofencesPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
  // console.log(isTablet + " " + isPhone);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  // console.log(isTablet);

  const user = useSelector((state) => state.session.user);
  const handleClose = () => {
    if (collapsed) {
      dispatch(layoutActions.openDeviceListMenu());
    }
    setCollapsed(!collapsed);
  };

  const handleGoBack = () => {
    if (localStorage.getItem('page') == 'geofence') {
      const status = 'success';
      window.location.href = 'moovetrax://return?status=' + status;
    }
    else {
      history.push('/main')
    }
  }

  const token = getToken()
  //console.log("session", session);
  if (!token) history.push('/login');

  useEffect(() => setCollapsed(isTablet), [isTablet]);

  const [geofenceTimestamp, setGeofenceTimestamp] = useState(Date.now());

  //////////////////////////// start tour part //////////////////////////////////
  const [isTourOpen, setIsTourOpen] = useState(false)
  const closeTour = () => {
    setIsTourOpen(false)
  }
  const openTour = () => {
    setIsTourOpen(true)
  }
  const steps = [
    {
      selector: '#tour-geofence-map',
      content: 'Use these buttons for geofence',
      style: {
        backgroundColor: '#ffffff',
      },
    }
  ]
  const accentColor = "#aad3df";

  const onTourClose = () => {
    console.log("onTourClose:::")
    window.localStorage.setItem('geofenceTourShowed', 1);
    closeTour()
  }

  const onTourStart = () => {
    const geofenceTourShowed = window.localStorage.getItem('geofenceTourShowed');
    console.log("geofenceTourShowed:::", geofenceTourShowed)

    if (geofenceTourShowed == 1) {
      //continue
    } else {
      setTimeout(() => {
        openTour()
      }, 1500)
    }
  }

  useEffect(() => {
    onTourStart()
  }, []);

  useEffect(() => {
    let selectedDeviceId = null
    if (id) {
      selectedDeviceId = id
      dispatch(geofencesActions.setSelectedDeviceId(selectedDeviceId));
    }
  }, [id]);

  //////////////////////////// end tour part //////////////////////////////////

  const [videoOpen, setVideoOpen] = useState(false)

  return (
    <>
      <div className={classes.root}>
        <Map>
          <CurrentLocationMap />
          <GeofenceEditMap
            geofenceTimestamp={geofenceTimestamp}
            setGeofenceTimestamp={setGeofenceTimestamp}
            handleClose={handleClose}
          />

          {/**** show devices *****/}
          <CurrentPositionsMap
            enablePopup={false}
          />
          <SelectedDeviceMap />
          <PoiMap />

        </Map>
        <Button
          variant="contained"
          color={isPhone ? 'secondary' : 'primary'}
          classes={{ containedPrimary: classes.sidebarToggleBg }}
          className={classes.sidebarToggle}
          onClick={handleClose}
          disableElevation
        >
          <ListIcon />
          <div className={classes.sidebarToggleText}>Geofences</div>
        </Button>
        <Paper square elevation={3} className={`${classes.sidebar} ${collapsed && classes.sidebarCollapsed}`}>
          <Paper className={classes.paper} square elevation={3}>
            <Toolbar className={classes.toolbar} disableGutters>
              {isTablet && (
                <IconButton onClick={handleGoBack}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <div className={classes.searchTextBox} >
                <Typography variant="h6" noWrap>Geofences</Typography>
              </div>

              {!isTablet && (
                <IconButton onClick={handleGoBack}>
                  <CloseIcon />
                </IconButton>
              )}
            </Toolbar>
          </Paper>
          <div className={classes.deviceList}>
            <GeofencesList
              geofenceTimestamp={geofenceTimestamp}
              setGeofenceTimestamp={setGeofenceTimestamp}
            />
            <div className={classes.videoBtnBox}>
              <ButtonBase disableRipple={true} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }} onClick={() => setVideoOpen(true)} >How To Video</ButtonBase>
            </div>
          </div>
        </Paper>
        <BottomMenu />

        <>
          <div id='tour-geofence-map' className={classes.tourGeofenceMap}>
          </div>
          <Tour
            onRequestClose={onTourClose}
            steps={steps}
            isOpen={isTourOpen}
            maskClassName="mask"
            className="helper"
            rounded={5}
            accentColor={accentColor}
            maskSpace={0}
            showButtons={false}
            showCloseButton={false}
            showNavigation={false}
            showNumber={false}
          // onAfterOpen={this.disableBody}
          // onBeforeClose={this.enableBody}
          />
        </>
      </div>

      <React.Fragment>
        {
          (videoOpen && GEOFENCE_VIDEO_ID !== "") ? (
            <>
              <ModalVideo
                channel='youtube'
                autoplay={1}
                youtube={{
                  autoplay: 1,
                  mute: 1
                }}
                isOpen={videoOpen}
                videoId={GEOFENCE_VIDEO_ID}
                onClose={() => setVideoOpen(false)}
              />
            </>
          ) : (<></>)
        }
      </React.Fragment>
    </>
  );
};

export default GeofencesPage;
