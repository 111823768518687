import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Button,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import EditCollectionView from "../EditCollectionView";
import OptionsLayout from "../settings/OptionsLayout";
import { useTranslation } from "../LocalizationProvider";

import moment from "moment";
import {
  api_call,
  console_log,
  empty,
  generateSharedFullUrl,
  getDistanceFromMiles,
  is_null,
} from "../helpers/untils";
import { useHistory } from "react-router-dom";
import { Stack } from "@mui/material";
import CoHostDeviceDataTable from "./CoHostDeviceDataTable";
import BatchGenerateLinkModal from "./modals/BatchGenerateLinkModal";
import TuroCalendarInputModal from "../share/TuroCalendarInputModal";

const useStyles = makeStyles((theme) => ({
  tableToolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
  },
  tableToolBarLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "340px",
    paddingTop: "1rem",
    [theme.breakpoints.only("xs")]: {},
  },
  tableToolBarRight: {
    maxWidth: "300px",
    width: "100%",
    paddingTop: "1rem",
    paddingLeft: "4px",
    textAlign: "right",
    [theme.breakpoints.only("xs")]: {
      textAlign: "left",
      paddingTop: "0.5rem",
    },
  },
  tableSearchText: {
    "& input.MuiInput-input": {
      paddingRight: "46px",
    },
  },
  tableSearchBtn: {
    borderRadius: 0,
    marginLeft: "-46px",
  },
  ///////////////////////////////////////////////////////
  radioGroupBox: {
    width: "100%",
    paddingLeft: "24px",
  },
  radioGroupListBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
  },
  inviteButton: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: 36,
    textTransform: "none",
  },
  coHostContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "flex-start",
    },
  },
  coHostButtonBox: {
    width: 120,
    height: 46,
    display: "inline-flex",
  },
}));

const LinksView = (props) => {
  const { updateTimestamp, setUpdateTimestamp, onMenuClick } = props;
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.session.user);

  /////////////////////////////radio button///////////////////////////////////////
  const showToast = (msg) => {
    alert(msg);
  };

  const defaultRadioValue = "active"; // ('all');
  const radioValueRef = useRef(defaultRadioValue);
  const [radioValue, setRadioValue] = useState(defaultRadioValue);
  const handleRadioChange = async (event) => {
    let v = event.target.value;
    console_log("v::::", v);
    setRadioValue(v);
    radioValueRef.current = v;

    if (v !== "co-host") {
      setPage(0);
      const filterOptions = {
        searchType: v,
      };
      let sort_order = sortOrder;
      if (v === "old") {
        sort_order = { name: "id", direction: "desc" };
      } else {
        sort_order = { name: "id", direction: "asc" };
      }
      await xhrRequest(0, rowsPerPage, sort_order, filterOptions);
    }
  };
  const [addDialogShown, setAddDialogShown] = useState(false);
  const onClickInvite = () => {
    setAddDialogShown(true);
  };
  ////////////////////////////////////////////////////////////////////////////////
  const devices = useSelector((state) => state.devices.items);
  const userDeviceItems = useSelector(
    (state) => state?.devices?.userDeviceItems
  );

  const getSharedFullUrl = (item) => {
    let useSecondDomain = false;
    const share_settings = item.share_settings;
    if (share_settings) {
      const share_settings_obj = JSON.parse(share_settings);
      if (share_settings_obj["useSecondDomain"]) {
        useSecondDomain = true;
      }
    }
    const url = generateSharedFullUrl(item.shareUrl, useSecondDomain);
    return url;
  };

  const dataRef = useRef([]);

  const columnDefs = [
    {
      name: "id",
      label: " ",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className="">
              <IconButton
                onClick={(event) =>
                  onMenuClick(
                    event.currentTarget,
                    value,
                    radioValueRef.current === "active" ? true : false
                  )
                }
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          );
        },
        //display: false
      },
    },
    {
      name: "user_email",
      label: "User",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return <div className="td-div">{value}</div>;
        },
      },
    },
    {
      name: "device_name",
      label: "Device",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return <div className="td-div">{value}</div>;
        },
      },
    },
    {
      name: "from",
      label: "From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return <div className="td-div">{moment(value).format("LLL")}</div>;
        },
      },
    },
    {
      name: "to",
      label: "To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return <div className="td-div">{moment(value).format("LLL")}</div>;
        },
      },
    },
    {
      name: "allot_miles",
      label: "Allot",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const item = dataRef.current[dataIndex] ?? {};
          return (
            <div className="td-div">
              <div
                className={`table-typograph ${
                  !is_null(item.allot_miles) &&
                  item.link_distance > item.allot_miles
                    ? "error"
                    : ""
                }`}
              >
                {getDistanceFromMiles(item.allot_miles, item.distance_unit)}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "link_distance",
      label: "Driven",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const item = dataRef.current[dataIndex] ?? {};
          return (
            <div className="td-div">
              <div
                className={`table-typograph ${
                  !is_null(item.allot_miles) &&
                  item.link_distance > item.allot_miles
                    ? "error"
                    : ""
                }`}
              >
                {getDistanceFromMiles(item.link_distance, item.distance_unit)}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "shareUrl",
      label: "Link",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const item = dataRef.current[dataIndex] ?? {};
          return <div className="td-div">{getSharedFullUrl(item)}</div>;
        },
      },
    },
  ];

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortOrder, setSortOrder] = useState({ name: "id", direction: "asc" });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(columnDefs);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = (e) => {
    const filter = e.target.value;
    setSearchText(filter);
    if (filter === "") {
      onSubmitChangeSearchText(filter);
    }
  };

  // mock async function
  const xhrRequest = async (
    page,
    rowsPerPage,
    sortOrder = {},
    filter_options = {}
  ) => {
    if (empty(user)) {
      return false;
    }

    setIsLoading(true);
    let api_url = "/api/links/get-data-table";
    let post_data = {};
    post_data["page"] = page;
    post_data["rowsPerPage"] = rowsPerPage;
    post_data["sortOrder"] = sortOrder;

    const filterOptions = {
      ...filter_options,
    };
    if (user.administrator) {
      filterOptions["userId"] = selectedUserId;
    } else {
      filterOptions["userId"] = user.id;
    }

    if (radioValueRef.current) {
      filterOptions["searchType"] = radioValueRef.current;
    }

    const response = await api_call(api_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data);
      dataRef.current = res.data;
      setPage(res.page);
      setSortOrder(sortOrder);
      setIsLoading(false);
      setCount(res.total);
      return res;
    } else {
      return false;
    }
  };

  const sort = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText,
    };
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions);
  };

  const changePage = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText,
    };
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions);
  };

  const onChangeRowsPerPage = (r) => {
    setRowsPerPage(r);
    setPage(0);
    const filterOptions = {
      search: searchText,
    };
    xhrRequest(0, r, sortOrder, filterOptions);
  };

  const onSubmitChangeSearchText = async (text) => {
    setPage(0);
    const filterOptions = {
      search: text,
    };
    await xhrRequest(0, rowsPerPage, sortOrder, filterOptions);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("handleSearchSubmit:::");
    onSubmitChangeSearchText(searchText);
  };

  useEffect(() => {
    const filterOptions = {
      search: searchText,
    };
    xhrRequest(0, rowsPerPage, sortOrder, filterOptions);
  }, [selectedUserId, updateTimestamp]);

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    onChangeRowsPerPage: onChangeRowsPerPage,
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,

    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  };

  const [pageData, setPageData] = useState({});
  const [showTuroCalendarInputModal, setShowTuroCalendarInputModal] =
    useState(false);

  const [showBatchGenerateLinkModal, setShowBatchGenerateLinkModal] =
    useState(false);
  const onClickBatchGenerate = () => {
    setShowBatchGenerateLinkModal(true);
  };

  return (
    <div>
      <FormControl className={classes.radioGroupBox}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={radioValue}
          onChange={handleRadioChange}
        >
          <div className={classes.radioGroupListBox}>
            <div style={{ display: "flex" }}>
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="old"
                control={<Radio />}
                label="Expired"
              />
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="future"
                control={<Radio />}
                label="Future"
              />

              {user.administrator ? (
                <></>
              ) : (
                <>
                  <Box>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      size="medium"
                      className={classes.inviteButton}
                      onClick={() => onClickBatchGenerate()}
                    >
                      Batch Generate
                    </Button>
                  </Box>
                </>
              )}
            </div>
            {user.administrator ? (
              <></>
            ) : (
              <>
                <div className={classes.coHostContainer}>
                  <FormControlLabel
                    value="co-host"
                    control={<Radio />}
                    label="Co-Host"
                  />
                  <div className={classes.coHostButtonBox}>
                    {radioValue === "co-host" ? (
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        size="medium"
                        className={classes.inviteButton}
                        onClick={() => onClickInvite()}
                      >
                        Invite
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </RadioGroup>
      </FormControl>

      <div className="mt-3">
        {radioValue === "co-host" ? (
          <>
            <CoHostDeviceDataTable
              devices={userDeviceItems}
              user={user}
              addDialogShown={addDialogShown}
              setAddDialogShown={setAddDialogShown}
            />
          </>
        ) : (
          <>
            <div className="custom-data-table-container">
              <MUIDataTable
                title={
                  <Box className={classes.tableToolBar}>
                    <div className={classes.tableToolBarLeft}>
                      {
                        <CircularProgress
                          size={24}
                          style={{
                            marginLeft: 15,
                            position: "relative",
                            top: 5,
                            visibility: isLoading ? "visible" : "hidden",
                          }}
                        />
                      }
                    </div>

                    <div className={classes.tableToolBarRight}>
                      <form onSubmit={handleSearchSubmit}>
                        <Stack
                          direction={`row`}
                          justifyContent={`flex-start`}
                          alignItems={`center`}
                          spacing={0}
                        >
                          <TextField
                            className={classes.tableSearchText}
                            fullWidth
                            value={searchText}
                            onChange={(e) => onChangeSearchText(e)}
                            label={`Search...`}
                            inputProps={{ type: "text" }}
                          />
                          <IconButton
                            size={`medium`}
                            className={classes.tableSearchBtn}
                            type="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </Stack>
                      </form>
                    </div>
                  </Box>
                }
                data={data}
                columns={columns}
                options={options}
                isLoading={isLoading}
              />
            </div>
          </>
        )}
      </div>

      {showBatchGenerateLinkModal ? (
        <>
          <BatchGenerateLinkModal
            open={showBatchGenerateLinkModal}
            setOpen={setShowBatchGenerateLinkModal}
            updateTimestamp={updateTimestamp}
            setUpdateTimestamp={setUpdateTimestamp}
            pageData={pageData}
            setPageData={setPageData}
            showTuroCalendarInputModal={showTuroCalendarInputModal}
            setShowTuroCalendarInputModal={setShowTuroCalendarInputModal}
          />
        </>
      ) : (
        <></>
      )}

      <TuroCalendarInputModal
        showToast={(msg) => showToast(msg)}
        //selectedDeviceId={selectedDeviceId}
        open={showTuroCalendarInputModal}
        setOpen={setShowTuroCalendarInputModal}
        pageData={pageData}
        setPageData={setPageData}
      />
    </div>
  );
};

const LinksDataTablePage = () => {
  return (
    <>
      <OptionsLayout>
        <EditCollectionView
          content={LinksView}
          editPath="/link"
          endpoint="links"
          disableAdd={true}
        />
      </OptionsLayout>
    </>
  );
};

export default LinksDataTablePage;
