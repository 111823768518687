import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { api_call, getToken, get_utc_timestamp_ms, setToken } from '../helpers/untils';
import { useEffectAsync } from '../reactHelper';
import { LinearProgress } from '@material-ui/core';
import { layoutActions, sessionActions } from '../store';
import { PAYMENT_VIDEO_ID } from '../config/constant';
import ModalVideo from 'react-modal-video';

export const AuthorizedLayout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState(null);

  useEffectAsync(async () => {
    const m_token = getToken()
    if (m_token) {
      const response = await api_call(`/api/auth/${m_token}`);
      if (response.ok) {
        let user_info = await response.json()
        dispatch(sessionActions.updateUser(user_info));
        setUserInfo(user_info)
      } else {
        setToken("");
        dispatch(sessionActions.updateUser({ id: 0 }));
        history.push('/login')
      }
    }
    return null;
  }, []);


  ///////////////////////////////////////////////////////////////////////////////////////////
  const [paymentVideoModalOpen, setPaymentVideoModalOpen] = useState(false);
  const paymentVideoModalTimestamp = useSelector((state) => state.layout.paymentVideoModalTimestamp);
  useEffect(() => {
    if (paymentVideoModalTimestamp) {
      setPaymentVideoModalOpen(true)
    }
  }, [paymentVideoModalTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////


  return (
    <div className="authorized-layout">
      <>
        {userInfo ? (
          <>
            {props.children}
          </>
        ) : (
          <>
            <LinearProgress />
          </>
        )}
      </>

      <React.Fragment>
        <ModalVideo
          channel='youtube'
          autoplay={1}
          youtube={{
            autoplay: 1,
            mute: 1
          }}
          isOpen={paymentVideoModalOpen}
          videoId={PAYMENT_VIDEO_ID}
          onClose={() => setPaymentVideoModalOpen(false)}
        />
      </React.Fragment>

    </div>
  );
};
