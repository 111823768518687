import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../store';

import {
  Grid, makeStyles, Typography, FormControlLabel, Checkbox, Box, Modal, TextField, Button, Snackbar,
} from '@material-ui/core';
import { api_call, empty, getLocalTimezone, get_data_value, get_utc_timestamp_ms, isGpsMT3VProtocolDevice, trimPhone } from '../helpers/untils';
import { DEFAULT_LOW_BATTERY_VOLT } from '../config/constant';
import { Stack } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import TimezoneAutoCompleteDropdown from '../components/TimezoneAutoCompleteDropdown';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '712px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
    minWidth: '600px'
  },
  formNotifierRect: {
    position: 'relative',
  },
  formNotifierTimezoneRect: {
    position: 'absolute',
    width: '250px',
    top: '15px',
    right: '10px',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkboxBlock: {
    width: 250,
  },
  inputBlock: {
    flex: 1,
  },
  checkboxOnlyBlock: {
    width: 100,
    display: 'none', //'flex'
    justifyContent: 'center',
  },
  formNotifier: {
    display: 'block',
    width: '100%',
    backgroundColor: '#f7f7f7',
    padding: '15px',
    marginBottom: '15px'
  },
  formNotifierLabel: {
    minWidth: '75px'
  },
  formNotifierItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

const LOCAL_TIMEZONE = getLocalTimezone()

const NotificationForm = (props) => {
  const { open, setOpen, selectedDevice, updateTimestamp, setDeviceItem } = props;
  const user = useSelector((state) => state.session.user);

  const firstSetting = useRef(false)

  const classes = useStyles();

  const [timezone, setTimezone] = useState(LOCAL_TIMEZONE);

  useEffect(() => {
    initNotificationSettings();
  }, [selectedDevice, updateTimestamp])

  useEffect(() => {
    if (selectedDevice?.timezone) {
      setTimezone(selectedDevice?.timezone)
    }
  }, [selectedDevice?.timezone])

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [apiLoading, setApiLoading] = useState(false)

  ///////////////////////////////////////////////////////////////////

  const handleSaveTimezoneSetting = async (option) => {
    const url = `/api/devices/notificationTimezone`;
    const payload = {
      user_id: user.id,
      device_id: selectedDevice['id'],
      timezone: option,
    }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      setDeviceItem({ ...selectedDevice, timezone: option })
      setToastMessage(`Timezone setting has been updated successfully`);
      setSnackbarOpen(true);
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }

  const getNotificationTypeList = () => {
    const notification_type_list = [
      {
        label: 'Unlock',
        value: 'Unlock',
        desc: "Unlock Command Sent"
      },
      {
        label: 'Lock',
        value: 'Lock',
        desc: "Lock Command Sent"
      },
      {
        label: 'Horn',
        value: 'Horn',
        desc: "Horn Command Sent"
      },
      {
        label: 'Unkill',
        value: 'Unkill',
        desc: "Unkill Command Sent"
      },
      {
        label: 'Kill',
        value: 'Kill',
        desc: "Kill Command Sent"
      },
      {
        label: 'Overspeed',
        value: 'Overspeed',
        desc: "Car Is Speeding"
      },
      // {
      //   label: 'Geofence',
      //   value: 'Geofence'
      // },
      {
        label: 'Offline',
        value: 'Offline',
        desc: "Device Shows Offline"
      },
      {
        label: 'Smoking',
        value: 'Smoking',
        desc: "Smoke Detected"
      },
      {
        label: 'Over Miles',
        value: 'Overmiles',
        desc: "Alotted Miles Exceeded"
      },
      {
        label: 'Unlocked by Bluetooth',
        value: 'Unlocked_by_Bluetooth',
        desc: ""
      },
      {
        label: 'Locked by Bluetooth',
        value: 'Locked_by_Bluetooth',
        desc: ""
      },
      {
        label: 'Bluetooth On',
        value: 'Bluetooth_On',
        desc: ""
      },
      {
        label: 'Bluetooth Off',
        value: 'Bluetooth_Off',
        desc: ""
      },
      {
        label: 'Low Battery',
        value: 'Low_Battery',
        desc: ""
      },
      {
        label: 'Hood',
        value: 'Hood',
        desc: ""
      },
      {
        label: 'Door',
        value: 'Door',
        desc: ""
      },
    ]
    const uniqueId = selectedDevice.uniqueId
    if (isGpsMT3VProtocolDevice(uniqueId)) {
      notification_type_list.push(
        {
          label: 'Shock',
          value: 'Shock',
          desc: ""
        }
      )
    }
    notification_type_list.push(
      {
        label: 'Device Suspension',
        value: 'Device_Suspension',
        desc: "Device Has Been Suspended"
      }
    )
    notification_type_list.push(
      {
        label: 'Ignition On',
        value: 'Ignition_ACC',
        desc: "Ignition Turned On"
      }
    )
    notification_type_list.push(
      {
        label: 'No Position Update',
        value: 'No_Position_Update',
        desc: "Car Position has not updated in 3+ Hours"
      }
    )
    notification_type_list.push(
      {
        label: 'No Trip Movement',
        value: 'No_Trip_Movement',
        desc: "No Trip Scheduled But Car Is Moving"
      }
    )
    notification_type_list.push(
      {
        label: 'Possible Towing',
        value: 'Possible_Towing',
        desc: "Ignition Is Off But Car Is Moving"
      }
    )
    notification_type_list.push(
      {
        label: 'Severe Weather',
        value: 'Severe_Weather',
        desc: "Severe Weather Expected Within An Hour"
      }
    )
    notification_type_list.push(
      {
        label: 'Late Return',
        value: 'Late_Return',
        desc: "Trip has ended but car is not at return location"
      }
    )
    return notification_type_list
  }
  const notificationTypeList = getNotificationTypeList()

  const [notificationSettingList, setNotificationSettingList] = useState({});

  const initNotificationSettings = () => {
    let notificationSettings = selectedDevice.notificationSettings
    if (!empty(notificationSettings)) {
      notificationSettings = JSON.parse(notificationSettings)
      notificationTypeList.map((item) => {
        if (notificationSettings[item.value] != undefined && notificationSettings[item.value]['status'] && notificationSettings[item.value]['notifyEmail'] == undefined && notificationSettings[item.value]['notifyPush'] == undefined) {
          notificationSettings[item.value]['notifyEmail'] = true
        }
      })
      firstSetting.current = false
    } else {
      notificationSettings = {}
      firstSetting.current = true
    }
    //console.log("firstSetting, notificationSettings:::::", firstSetting.current, notificationSettings)
    setNotificationSettingList(notificationSettings)
  }

  const onChangeNotificationSettingStatus = (type, event, notifyType) => {
    const settings = { ...notificationSettingList }
    if (empty(settings[type])) {
      settings[type] = {
        status: false,
        email: '',
        post_on_turo: false,
        notifyEmail: false,
        notifyPush: false,
      }
    }
    if (notifyType == 'email') {
      settings[type]['notifyEmail'] = event.target.checked
      settings[type]['status'] = event.target.checked
    } else if (notifyType == 'push') {
      settings[type]['notifyPush'] = event.target.checked
    }

    setNotificationSettingList(settings)
  }
  const onChangeNotificationSettingEmail = (type, event) => {
    const settings = { ...notificationSettingList }
    if (false && firstSetting.current) {
      bulkUpdateNotificationSettingEmail(event)
    } else {
      if (empty(settings[type])) {
        settings[type] = {
          status: false,
          email: '',
          post_on_turo: false
        }
      }
      settings[type]['email'] = event.target.value
      setNotificationSettingList(settings)
    }
  }

  const onChangeNotificationSettingValue = (type, event) => {
    const settings = { ...notificationSettingList }
    if (empty(settings[type])) {
      settings[type] = {
        status: false,
        email: '',
        value: '',
        post_on_turo: false,
        notifyEmail: false,
        notifyPush: false
      }
    }
    settings[type]['value'] = event.target.value
    setNotificationSettingList(settings)
  }

  const onChangeNotificationSettingPostOnTuro = (type, event) => {
    const settings = { ...notificationSettingList }
    if (empty(settings[type])) {
      settings[type] = {
        status: false,
        email: '',
        post_on_turo: false
      }
    }
    settings[type]['post_on_turo'] = event.target.checked
    setNotificationSettingList(settings)
  }

  const bulkUpdateNotificationSettingEmail = (event) => {
    const val = event.target.value
    const settings = { ...notificationSettingList }
    for (let k in notificationTypeList) {
      const typeInfo = notificationTypeList[k]
      const type = typeInfo['value']
      if (empty(settings[type])) {
        settings[type] = {
          status: false,
          email: val,
          post_on_turo: false
        }
      } else {
        settings[type]['email'] = val
      }
    }
    setNotificationSettingList(settings)
  }

  const handleSaveSetting = async () => {
    const url = `/api/devices/notificationSetting`;
    const payload = {
      user_id: user.id,
      user_name: user.name,
      device_id: selectedDevice['id'],
      notificationSettingList: JSON.stringify(notificationSettingList),
    }
    // if (Number(payload['amount']) <= 0) {
    //   setToastMessage(`Invalid amount`);
    //   setSnackbarOpen(true);
    //   return false
    // }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Notification setting has been updated successfully`);
      setSnackbarOpen(true);
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }

  const onClickCopyToAllCars = async () => {
    const url = `/api/devices/copyNotificationSettingToAll`;
    const payload = {
      user_id: user.id,
      user_name: user.name,
      device_id: selectedDevice['id'],
      notificationSettingList: JSON.stringify(notificationSettingList),
    }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Notification setting has been copied to all cars successfully`);
      setSnackbarOpen(true);
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }

  const checkPostOnTuroChecked = (itemType) => {
    if (notificationSettingList[itemType.value]) {
      if (notificationSettingList[itemType.value]['post_on_turo']) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const yourphonenumber = user.phone ? trimPhone(user.phone) : "yourphonenumber"
  const [showTooltip, setShowTooltip] = useState(true) //todo quan

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      <div className={classes.formContainer}>
        <div className={classes.formNotifierRect}>
          <div className={classes.formNotifier}>
            <div className={classes.formNotifierItem}>
              <span className={classes.formNotifierLabel}>{`AT&T:`}</span>
              <span>{`${yourphonenumber}@txt.att.net`}</span>
            </div>
            <div className={classes.formNotifierItem}>
              <span className={classes.formNotifierLabel}>{`T-Mobile:`}</span>
              <span>{`${yourphonenumber}@tmomail.net`}</span>
            </div>
            <div className={classes.formNotifierItem}>
              <span className={classes.formNotifierLabel}>{`Verizon:`}</span>
              <span>{`${yourphonenumber}@vtext.com`}</span>
            </div>
          </div>
          <div className={classes.formNotifierTimezoneRect}>
            <Grid item xs={24} sm={24}>
              <TimezoneAutoCompleteDropdown
                device={selectedDevice}
                timezone={timezone}
                setTimezone={setTimezone}
                handleChangeTimezone={(v) => handleSaveTimezoneSetting(v)}
              />
            </Grid>
          </div>
        </div>

        <Grid container sx={{
          display: 'flex',
          width: '100%',
        }}>
          <Grid item xs={3}>
            <Typography sx={{ width: '25%' }}>
              Push
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ width: '25%', }}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ width: '50%', fontSize: '14px' }}>
              Send Email to (separate with comma)
            </Typography>
          </Grid>
        </Grid>

        {
          (notificationTypeList.map((itemType, index) => {
            return (
              <Grid container sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row'
              }}
                key={index}
              >
                <Grid item xs={3}>
                  <FormControlLabel
                    control={<Checkbox checked={notificationSettingList[itemType.value] && notificationSettingList[itemType.value]['notifyPush'] ? true : false}
                      onChange={(event) => onChangeNotificationSettingStatus(itemType.value, event, 'push')} />}
                    label={itemType.label}
                  />
                  {(itemType.value === 'Low_Battery') && (
                    <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TextField
                        margin="small"
                        value={notificationSettingList[itemType.value] ? get_data_value(notificationSettingList[itemType.value], 'value', DEFAULT_LOW_BATTERY_VOLT) : DEFAULT_LOW_BATTERY_VOLT}
                        onChange={(event) => onChangeNotificationSettingValue(itemType.value, event)}
                        label=""
                        variant="filled"
                        type="number"
                        style={{ width: '50px' }}
                      />&nbsp;&nbsp;V
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex' }}>
                  <FormControlLabel
                    control={<Checkbox checked={notificationSettingList[itemType.value] && notificationSettingList[itemType.value]['notifyEmail'] ? true : false}
                      onChange={(event) => onChangeNotificationSettingStatus(itemType.value, event, 'email')} />}
                    label={itemType.label}
                  />
                  {(itemType.value === 'Low_Battery') && (
                    <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TextField
                        margin="small"
                        value={notificationSettingList[itemType.value] ? get_data_value(notificationSettingList[itemType.value], 'value', DEFAULT_LOW_BATTERY_VOLT) : DEFAULT_LOW_BATTERY_VOLT}
                        onChange={(event) => onChangeNotificationSettingValue(itemType.value, event)}
                        label=""
                        variant="filled"
                        type="number"
                        style={{ width: '50px' }}
                      />&nbsp;&nbsp;V
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={6} >
                  <Box sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>

                    {
                      (showTooltip && itemType.desc) && (
                        <Tooltip
                          arrow
                          title={itemType.desc}
                        >
                          <InfoIcon color="action" />
                        </Tooltip>
                      )
                    }
                    <TextField
                      value={notificationSettingList[itemType.value] ? notificationSettingList[itemType.value]['email'] : ''}
                      onChange={(event) => onChangeNotificationSettingEmail(itemType.value, event)}
                      label=""
                      variant="filled"
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            )
          }))
        }
      </div>

      {
        (!user.administrator) ? (
          <>
            <div style={{ padding: '15px' }}>
              <Stack direction={`row`} justifyContent={`center`} alignItems={`center`}>
                <Button type="button" color="secondary" variant="outlined" style={{ height: 'auto' }} onClick={() => onClickCopyToAllCars()} disabled={apiLoading}>
                  Copy Settings To All My Cars
                </Button>
              </Stack>
            </div>
          </>
        ) : (
          <></>
        )
      }

      <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
        <Button type="button" color="primary" variant="contained" onClick={() => handleSaveSetting()} disabled={apiLoading}>
          Save
        </Button>
        <Button type="button" color="primary" variant="outlined" onClick={async () => {
          var fromMobile = await localStorage.getItem("fromMobile");
          if (fromMobile == "true") {
            const status = 'success';
            window.location.href = 'moovetrax://return?status=' + status;
          } else {
            setOpen(false);
          }
        }
        }>
          Close
        </Button>
      </div>
    </>
  )
}
const MT2VNotificationModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem } = props;
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (deviceItem && deviceItem.id && open) {
      loadDeviceInfo(deviceItem.id);
    }
  }, [deviceItem, open])

  const loadDeviceInfo = async (deviceId) => {
    const url = `/api/devices/${deviceId}`
    const response = await api_call(url);
    if (response.ok) {
      const device = await response.json()
      setItem(device);
      setUpdateTimestamp(get_utc_timestamp_ms())
    }
  }
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />

          <Typography id="modal-modal-title" variant="h6" component="h2">Notification</Typography>

          <div className='mt-3' style={{ maxHeight: "calc(100vh - 340px)", overflow: "auto" }}>
            {
              (!empty(item)) ? (
                <div className=''>
                  <NotificationForm
                    open={open}
                    setOpen={setOpen}
                    selectedDevice={item}
                    updateTimestamp={updateTimestamp}
                    setUpdateTimestamp={setUpdateTimestamp}
                    setDeviceItem={setItem}
                  />
                </div>
              ) : (
                <div className='text-center'>
                  Loading....
                </div>
              )
            }
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MT2VNotificationModal;
