import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Divider,
  Drawer,
  makeStyles,
  IconButton,
  Hidden,
  Button,
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SideNav from '../../components/SideNav';
import NavBar from '../../components/NavBar';
import useRoutes from './useRoutes';
import { useTranslation } from '../../LocalizationProvider';
import { getIsAdmin } from '../../common/selectors';
import { useSelector } from 'react-redux';
import { api_call } from '../../helpers/untils';
import { getSettingPersist, setSettingPersist } from '../../helpers/misc';

const useStyles = makeStyles((theme) => ({
  drawerFooter: {
    // position: 'absolute',
    // bottom: theme.spacing(1),
    // left: theme.spacing(1),
    // right: theme.spacing(1),
    padding: theme.spacing(1, 2),
  },
  drawerFooterContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    opacity: 0.7
  },
  drawerFooterContentItem: {
    marginBottom: theme.spacing(1),
  },
  drawerFooterContentItemLabel: {
    minWidth: '5em',
    display: 'inline-block'
  },
  exitButtonContainer: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
}));

const FooterBox = (props) => {
  const { serverInfo, isAdmin } = props
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className={classes.exitButtonContainer}>
        <>
          <Button startIcon={<ArrowBackIcon />} onClick={() => {
            var fromMobile = localStorage.getItem("fromMobile");
            var page = localStorage.getItem("page")
            if (fromMobile == "true" && page == "log") {
              const status = 'success';
              window.location.href = 'moovetrax://return?status=' + status;
            } else {
              history.push('/main')
            }
          }}>
            Exit
          </Button>
        </>
      </div>

      {
        isAdmin ? (
          <>
            <div className={classes.drawerFooter}>
              <div className={classes.drawerFooterContent}>
                <p className={classes.drawerFooterContentItem}><span className={classes.drawerFooterContentItemLabel}>Private IP:</span><span>{serverInfo.serverPrivateIp}</span> </p>
                <p className={classes.drawerFooterContentItem}><span className={classes.drawerFooterContentItemLabel}>Public IP:</span><span>{serverInfo.serverPublicIp}</span> </p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

export default FooterBox;
