import { API_BASE_URL, BASE_SITE_URL, DISTANCE_UNIT_LIST, GOOGLE_MAP_API_KEY, MACHINE_ENVIRONMENT, SHARE_BASE_URL, SHARE_SECONDARY_BASE_URL, TEST_MT3V_DEVICE_GPS_IDS, WEB_CLIENT_VERSION } from "../config/constant";

import moment from 'moment';
import momentZ from 'moment-timezone';

export const console_log = (...log_data) => {
  if (MACHINE_ENVIRONMENT === "prod") {
    return false
  } else {
    console.log(...log_data)
  }
}
export const empty = (value) => {
  if (value === undefined || value === null || value === '' || value === false) {
    return true;
  } else {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object") {
      return isEmptyObject(value);
    } else {
      return false;
    }
  }
}
export const get_data_value = (obj, key, default_value = '') => {
  if (empty(obj)) {
    return default_value
  }
  if (obj[key] !== undefined) {
    return obj[key]
  } else {
    return default_value
  }
}
export const api_call = async (url, params = null) => {
  try {
    let token = getToken()
    const headers = { 'Authorization': 'Bearer ' + token, 'moovetrax-client-type': 'web', 'moovetrax-client-version': WEB_CLIENT_VERSION }; // auth header with bearer token
    // console.log("headers::::", headers)

    let response = null;
    if (params) {
      if (params['headers']) {
        params['headers'] = { ...params['headers'], ...headers }
      } else {
        params['headers'] = headers
      }
      response = await fetch(API_BASE_URL + url, params);
    } else {
      params = {}
      params['headers'] = headers
      response = await fetch(API_BASE_URL + url, params);
    }

    if (response.ok) {
      // continue
    } else {
      const errorObj = await response.json();
      // console.log("errorObj::::", errorObj)
      if (errorObj && errorObj['login_required']) {
        setToken("");
        window.location.href = `/login`
        return errorObj
      }
      return errorObj;
    }
    return response;
  } catch (e) {
    return false
  }
}

export const rand = () => Math.floor((1 + Math.random()) * 0x10000000000000).toString(16).substring(1);

export const randomString = (length, number_only = false) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  if (number_only) {
    characters = '0123456789';
  }
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const removeItemFromArray = (arr, val) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      arr.splice(i, 1);
      i--;
    }
  }
  return arr
};

export const addItemToArray = (arr, val) => {
  if (!arr.includes(val)) {
    arr = [...arr, val]
  }
  return arr
};

export const createArrayFromCount = (count) => {
  let arr = []
  for (let i = 0; i < count; i++) {
    arr = [...arr, i]
  }
  return arr
};

export const createEmptyArrayFromCount = (count, defaultValue = "") => {
  let arr = []
  for (let i = 0; i < count; i++) {
    arr = [...arr, defaultValue]
  }
  return arr
};

export const isEmpty = (list, field, errorList) => {
  var newErrorList = []
  if (list[field] === undefined || list[field] === null || list[field] === "") { //empty field
    newErrorList = addItemToArray(errorList, field)
  } else { //field is valie
    newErrorList = removeItemFromArray(errorList, field)
  }
  return newErrorList;
};
export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export const is_null = (value) => {
  if (value === undefined || value === null) {
    return true;
  } else {
    return false;
  }
}
export const copyObject = (obj) => {
  if (is_null(obj)) {
    return obj
  } else {
    return JSON.parse(JSON.stringify(obj))
  }
}
export const get_utc_timestamp = () => {
  let a = 0;
  let timestamp = new Date().getTime();
  a = Math.floor(timestamp / 1000); //a = Math.floor(Date.now() / 1000);
  return a;
};

export const get_utc_timestamp_ms = () => {
  let timestamp = new Date().getTime();
  return timestamp;
};

export const timeConverter = (delta, with_ago = true) => {
  var delta_time_arr = [];
  var day = Math.floor(delta / 86400);
  if (day > 0) {
    delta_time_arr.push(day + " day" + (day > 1 ? "s" : ""))
  }
  delta = delta - day * 86400;
  var hour = Math.floor(delta / 3600);
  if (hour > 0) {
    delta_time_arr.push(hour + " hour" + (hour > 1 ? "s" : ""))
  }
  delta = delta - hour * 3600;
  var min = Math.floor(delta / 60);
  if (min > 0) {
    delta_time_arr.push(min + " minute" + (min > 1 ? "s" : ""))
  }
  return delta_time_arr.join(" and ")
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const addSufixToFileName = (fileName, suffix = "_compressed") => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) return fileName; // No extension found

  const baseName = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex);

  return `${baseName}${suffix}${extension}`;
}


export const getDeviceImageUri = (device, compressed = false) => {
  let uri = `images/icon/Default.svg`
  if (device.category) {
    uri = `images/icon/${device.category}.svg`
  }
  if (device.image) {
    uri = BASE_SITE_URL + "/" + device.image
    if (compressed) {
      uri = addSufixToFileName(uri)
    }
  }
  return uri
}

export const getPostalCodeFromGoogleGeoLocation = (locationData) => {
  try {
    const address_component_list = locationData.results
    for (let k in address_component_list) {
      const comp = address_component_list[k].address_components
      for (let k1 in comp) {
        const item = comp[k1]
        if (item['types'].includes("postal_code")) {
          return item['long_name']
        }
      }
    }
    return false;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}
export const getStateFromGoogleGeoLocation = (locationData) => {
  try {
    const address_component_list = locationData.results
    for (let k in address_component_list) {
      const comp = address_component_list[k].address_components
      for (let k1 in comp) {
        const item = comp[k1]
        if (item['types'].includes("administrative_area_level_1")) {
          return item['long_name']
        }
      }
    }
    return false;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}
export const getCountryFromGoogleGeoLocation = (locationData) => {
  try {
    const address_component_list = locationData.results
    for (let k in address_component_list) {
      const comp = address_component_list[k].address_components
      for (let k1 in comp) {
        const item = comp[k1]
        if (item['types'].includes("country")) {
          return item['short_name']
        }
      }
    }
    return false;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}
export const getStreetNumberFromGoogleGeoLocation = (locationData) => {
  try {
    const address_component_list = locationData.results
    for (let k in address_component_list) {
      const comp = address_component_list[k].address_components
      for (let k1 in comp) {
        const item = comp[k1]
        if (item['types'].includes("street_number")) {
          return item['short_name']
        }
      }
    }
    return false;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}
export const getRouteFromGoogleGeoLocation = (locationData) => {
  try {
    const address_component_list = locationData.results
    for (let k in address_component_list) {
      const comp = address_component_list[k].address_components
      for (let k1 in comp) {
        const item = comp[k1]
        if (item['types'].includes("route")) {
          return item['short_name']
        }
      }
    }
    return false;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}
export const getCityFromGoogleGeoLocation = (locationData) => {
  try {
    const address_component_list = locationData.results
    for (let k in address_component_list) {
      const comp = address_component_list[k].address_components
      for (let k1 in comp) {
        const item = comp[k1]
        if (item['types'].includes("locality")) {
          let long_name = item['long_name']
          if (long_name.length > 10) {
            long_name = item['short_name']
          }
          return long_name
        }
      }
    }
    return false;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}
export const getLatLngFromGoogleGeoLocation = (locationData) => {
  try {
    const latlng = locationData.results[0].geometry.location;
    const position = {
      "lat": latlng.lat,
      "lng": latlng.lng
    };
    return position;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}

export const getFormattedAddressFromGoogleGeoLocation = (locationData) => {
  try {
    const formatted_address = locationData.results[0].formatted_address;
    return formatted_address;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}

export const getAddressFromGoogleGeoLocation = (locationData) => {
  try {
    //const postalCode = getPostalCodeFromGoogleGeoLocation(locationData)
    const state = getStateFromGoogleGeoLocation(locationData)
    //const country = getCountryFromGoogleGeoLocation(locationData)
    const street_number = getStreetNumberFromGoogleGeoLocation(locationData)
    const route = getRouteFromGoogleGeoLocation(locationData)
    const city = getCityFromGoogleGeoLocation(locationData)
    let address = ""
    //address += getFormattedAddressFromGoogleGeoLocation(locationData);

    //const position = getLatLngFromGoogleGeoLocation(locationData)
    //address += "  ------ " + position.lat + "  -------  " + position.lng + "   --------   "
    if (street_number) {
      address += street_number
    }
    if (route) {
      address += " " + route
    } else {
      address += " " + "Unknown street"
    }
    if (city) {
      address += ", " + city
    } else {
      //address += state
    }
    return address;
  } catch (error) {
    console.log('error:::', error)
    return false
  }
}

export const getGoogleGeoLocationAddress = async (lat, lng) => {
  try {
    var apiKey = GOOGLE_MAP_API_KEY;
    var latlng = String(lat) + ',' + String(lng);
    var address = "";
    const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlng + "&sensor=true&key=" + apiKey);
    if (response.ok) {
      var res = await response.json();
      var comp = res.results[0].address_components;
      //address = get_data_value(comp[0], "long_name") + " " + get_data_value(comp[1], "long_name") + ", " + get_data_value(comp[3], "long_name");
      address = getAddressFromGoogleGeoLocation(res)
    }
    return address
  } catch (error) {
    console.log('getGoogleGeoLocationAddress error:::', error)
    return ""
  }
}

export const convertUtcToTimezone = (utc_datetime_str, iana_timezone) => { //utc_datetime_str: utc iso format
  let currentMoment = null
  try {
    const utcCutoff = moment.utc(utc_datetime_str);
    currentMoment = utcCutoff.clone().tz(iana_timezone);
    return currentMoment
  } catch (e) {
    console.log("convertUtcToTimezone error", e)
    return currentMoment
  }
}

export const getMomentFromTimezone = (datetime, iana_timezone, fmt = "YYYY-MM-DD HH:mm:ss") => { // datetime: YYYY-MM-DDTHH:mm:ss (local date time)  iana_timezone: ex: "Australia/Sydney" (local timezone)
  let currentMoment = null
  try {
    console_log("datetime,iana_timezone:::", datetime, iana_timezone)
    currentMoment = moment.tz(datetime, fmt, iana_timezone);
    return currentMoment
  } catch (e) {
    console.log("getMomentFromTimezone error", e)
    currentMoment = moment(datetime, fmt)
    // const utc_datetime = currentMoment.toISOString()
    // console_log("utc_datetime:::", utc_datetime)
    return currentMoment
  }
}

export const getUtcFromTimezone = (datetime, iana_timezone, fmt = "YYYY-MM-DD HH:mm:ss") => { // datetime: YYYY-MM-DDTHH:mm:ss (local date time)  iana_timezone: ex: "Australia/Sydney" (local timezone)
  let currentMoment = null
  try {
    console_log("datetime,iana_timezone:::", datetime, iana_timezone)
    currentMoment = moment.tz(datetime, fmt, iana_timezone);
    currentMoment.utc();
    // const utc_datetime = currentMoment.toISOString()
    // console_log("utc_datetime:::", utc_datetime)
    return currentMoment
  } catch (e) {
    console.log("getUtcFromTimezone error", e)
    currentMoment = moment(datetime, fmt)
    currentMoment.utc()
    // const utc_datetime = currentMoment.toISOString()
    // console_log("utc_datetime:::", utc_datetime)
    return currentMoment
  }
}

export const getFormattedTime = (dateStr, isOnlyStr = false) => {
  try {
    let date = new Date(dateStr);

    // Extract the components
    let year = date.getUTCFullYear();
    let month = String(date.getUTCMonth() + 1).padStart(2, '0');
    let day = String(date.getUTCDate()).padStart(2, '0');
    let hours = String(date.getUTCHours()).padStart(2, '0');
    let minutes = String(date.getUTCMinutes()).padStart(2, '0');
    let seconds = String(date.getUTCSeconds()).padStart(2, '0');

    // Format the date string
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    if (isOnlyStr) {
      return (formattedDate)
    }
    return moment(formattedDate)

  } catch (error) {
    console.log(error)
    return null
  }
}

export const getUnixTimestampFromTimezone = (datetime, iana_timezone) => { // datetime: YYYY-MM-DDTHH:mm:ss (local date time)  iana_timezone: ex: "Australia/Sydney" (local timezone)
  let currentMoment = null
  try {
    if (empty(iana_timezone)) {
      currentMoment = moment(datetime, "YYYY-MM-DD HH:mm:ss")
    } else {
      currentMoment = momentZ.tz(datetime, iana_timezone)
    }
    //const currentTime = currentMoment.toISOString()
    //console.log("currentTime:::", currentTime)
    const currentUnixTimeStamp = currentMoment.unix()
    //console.log("currentUnixTimeStamp:::", currentUnixTimeStamp)
    return currentUnixTimeStamp
  } catch (e) {
    console.log("getUnixTimestampFromTimezone error", e)
    currentMoment = moment(datetime, "YYYY-MM-DD HH:mm:ss")
    const currentUnixTimeStamp = currentMoment.unix()
    //console.log("currentUnixTimeStamp:::", currentUnixTimeStamp)
    return currentUnixTimeStamp
  }
}

export const convertUnixTimestampToTimezone = (unixtimestamp, format, iana_timezone) => { //  iana_timezone: ex: "Australia/Sydney" (local timezone)
  let currentMoment = null
  try {
    //momentZ.unix(unixtimestamp).tz('Europe/London').format("MM/DD/YYYY h:mm a")

    if (empty(iana_timezone)) {
      currentMoment = moment.unix(unixtimestamp)
    } else {
      currentMoment = momentZ.unix(unixtimestamp).tz(iana_timezone)
    }
    if (!empty(format)) {
      const currentTime = currentMoment.format(format)
      return currentTime
    } else {
      return currentMoment
    }
  } catch (e) {
    console.log("convertUnixTimestampToTimezone error", e)
    return false
  }
}

export const isoDateToTimezoneDate = (isoDateString) => { //convert iso string to datetime of that timezone
  //var str = moment.parseZone(isoDateString).format('YYYY-MM-DD HH:mm:ss')
  var str = moment.parseZone(isoDateString).format('LLL')
  return str
}
export const isoDateToUtcDate = (isoDateString) => {
  var str = moment.utc(isoDateString).format('YYYY-MM-DD HH:mm:ss')
  return str
}

export const getLocalTimezone = () => { // return  iana_timezone: ex: "Australia/Sydney" (local timezone)
  let tz = null
  try {
    tz = momentZ.tz.guess();
  } catch (e) {
    console.log("getLocalTimezone error:::", e)
  }
  return tz
}

export const getTimezoneList = (continentCity = true) => {
  let timeZones = null
  try {
    timeZones = momentZ.tz.names();
    if (continentCity) {
      const continentCityTimezones = timeZones.filter(
        tz => (tz.includes('Africa/') || tz.includes('America/') || tz.includes('Asia/') || tz.includes('Australia/') || tz.includes('Europe/'))
      );
      return ['GMT', ...continentCityTimezones]
    } else {
      const timezoneList = timeZones.filter(
        tz => (tz.includes('GMT'))
      )
      return timezoneList
    }
  } catch (e) {
    console.log("getTimezoneList error:::", e)
  }
  return timeZones
}

export const checkIsDST = (dataStr, ianaTimezone) => { //"2024-07-01", "America/New_York"
  try {
    let date = momentZ.tz(dataStr, ianaTimezone);
    return date.isDST(); // true (DST is observed)
  } catch (e) {
    console.log("isDST error:::", e)
    return false
  }
}

export const trimPhone = (num) => {
  num = num.replace('+', '');
  num = num.replace('-', '');
  num = num.replace('_', '');
  num = num.replace('-', '');
  num = num.replace('(', '');
  num = num.replace(')', '');
  num = num.replace(' ', '');
  num = num.replace(/ /g, '');
  let last10 = num.length > 10 ? num.slice(-10) : num
  return last10;
}

export const getToken = () => {
  let m_token = window.localStorage.getItem('m_token');
  const params = new URLSearchParams(window.location.search);
  if (empty(m_token)) {
    m_token = params.get('token');
    if (m_token) {
      window.localStorage.setItem('fromMobile', true);
    } else {
      m_token = ""
      window.localStorage.setItem('fromMobile', false);
    }
    setToken(m_token)
  }
  return m_token
}

export const setToken = (m_token = "") => {
  window.localStorage.setItem('m_token', m_token);
  return m_token
}

export const getShareUri = () => {
  const shareUri = window.localStorage.getItem('shareUri');
  return shareUri
}
export const setShareUri = (shareUri = "") => {
  window.localStorage.setItem('shareUri', shareUri);
  return shareUri
}

export const generateSharedUrlId = (deviceNickname = "") => {
  let sharedUrl = `${randomString(10).toLowerCase()}${randomString(5).toLowerCase()}`
  if (deviceNickname) {
    //deviceNickname = deviceNickname.trim().toLowerCase().replaceAll(' ', '_').replaceAll('*', '_').replaceAll('#', '_').replaceAll('&', '_').replaceAll('?', '_');
    deviceNickname = deviceNickname.trim().toLowerCase().replaceAll(' ', '_').replace(/[^a-zA-Z0-9_]/g, '');
    sharedUrl += `_${deviceNickname}`
  }
  let utcStr = moment.utc().format('YYYYMMDD_HHmmss')
  sharedUrl += `_${utcStr}`
  return sharedUrl
}

export const generateSharedFullUrl = (sharedUrlId, useSecondDomain = false) => {
  let shared_url = ""
  if (useSecondDomain) {
    shared_url = SHARE_SECONDARY_BASE_URL + sharedUrlId
  } else {
    shared_url = SHARE_BASE_URL + sharedUrlId
  }
  return shared_url
}

export const isGpsMTXVProtocolDevice = (gpsDevid = "") => {
  try {
    if (empty(gpsDevid)) {
      return false
    }
    let checkStr = gpsDevid.substring(0, 4);
    const regex = /^MT[2-9]V$/; // Matches "MT2V" to "MT9V"
    return regex.test(checkStr);
  } catch (error) {
    console.log("isGpsMTXVProtocolDevice error:::", error, gpsDevid);
    return false
  }
}

export const isGpsMT3VProtocolDevice = (gpsDevid = "") => {  // check gpsId starts with MT3V or not
  try {
    let checkStr = gpsDevid.substring(0, 4);
    if (checkStr === "MT3V" || TEST_MT3V_DEVICE_GPS_IDS.includes(gpsDevid)) { // new protocol
      return true
    } else { // old protocol
      return false
    }
    return false
  } catch (error) {
    console.log("isGpsMT3VProtocolDevice error:::", error);
    return false
  }
}

export const isGpsMT2VProtocolDevice = (gpsDevid = "") => {  // check gpsId starts with MT2V or not
  try {
    if (gpsDevid.length > 4) {
      let checkStr = gpsDevid.substring(0, 4);
      //console.log("isGpsMT2VProtocolDevice checkStr:::", checkStr)
      if (checkStr === "MT2V") { // new protocol
        return true
      } else {
        return isGpsMT3VProtocolDevice(gpsDevid)
      }
    } else {
      return false
    }
  } catch (error) {
    console_log("isGpsMT2VProtocolDevice error:::", gpsDevid, error);
    return false
  }
}

export const isContainsOnlyDigits = (str) => {
  let regex = /\D/;
  let bool = regex.test(str);
  if (bool) {
    // contains non-digits 
    return false
  } else {
    // contains only digits
    return true
  }
}

export const isOnlyDigits = (string) => {
  for (let i = 0; i < string.length; i++) {
    var ascii = string.charCodeAt(i);
    if (ascii < 48 || ascii > 57) {
      return false;
    }
  }
  return true;
}

export const onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9]*$/.test(str);
}

export const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}
export const containsWhiteSpace = (str) => {
  return /\s/g.test(str);
}

export const calculateResellerDeviceCount = (startDeviceId, endDeviceId) => {
  if (empty(startDeviceId) || empty(endDeviceId)) {
    return false
  }
  if (startDeviceId.length !== endDeviceId.length) {
    return false
  }

  startDeviceId = startDeviceId.toUpperCase();
  endDeviceId = endDeviceId.toUpperCase();

  const num = 4
  let prefixStart = startDeviceId.substring(0, num);
  let prefixEnd = endDeviceId.substring(0, num);

  if (prefixStart !== prefixEnd) {
    return false
  }

  let numberStart = startDeviceId.substring(num);
  let numberEnd = endDeviceId.substring(num);
  if (empty(numberStart) || empty(numberEnd)) {
    return false
  }

  let start = Number(numberStart)
  let end = Number(numberEnd)
  let cnt = end - start + 1
  if (cnt <= 0) {
    return false
  }
  return cnt
}

export const getDeviceDistanceUnit = (distance_unit) => { //unit: mile, km
  return ""
  const unitItem = DISTANCE_UNIT_LIST.find((item) => item.id === distance_unit)
  if (unitItem && unitItem.id) {
    return unitItem.unit
  } else {
    return ""
  }
}

export const getDeviceSpeedUnit = (distance_unit) => { //unit: mile, km
  const unitItem = DISTANCE_UNIT_LIST.find((item) => item.id === distance_unit)
  if (unitItem && unitItem.id) {
    return unitItem.speed_unit
  } else {
    return ""
  }
}

export const getDistanceFromMiles = (miles, unit = 'km', withUnit = true, number_format = false) => { //unit: mile, km
  // if(empty(unit)) {
  //   unit = 'mile'
  // }
  if (is_null(miles)) {
    miles = 0
  }
  miles = Number(miles)
  if (unit === 'km') {
    const conversionFactor = 1.609344;
    const kilometers = miles * conversionFactor;
    miles = kilometers
  }
  else if (unit === 'm') {
    const conversionFactor = 1.609344;
    const meters = 1000 * miles * conversionFactor;
    miles = meters
  }
  if (number_format) {
    miles = Number(miles.toFixed(0)) //miles = Number(miles.toFixed(1))
    return miles
  } else {
    miles = Number(miles.toFixed(0)) //miles = Number(miles.toFixed(1))
    miles = miles.toLocaleString();

    if (withUnit) {
      const unitStr = getDeviceDistanceUnit(unit)
      miles = `${miles} ${unitStr}`
    } else {
      miles = `${miles}`
    }
    return miles
  }
}

// export const getDistanceFromKilometers = (kilometers, unit = 'mile', withUnit = true) => { //unit: mile, km
//   // if(empty(unit)) {
//   //   unit = 'mile'
//   // }
//   if(is_null(kilometers)) {
//     kilometers = 0
//   }
//   kilometers = Number(kilometers)
//   if (unit === 'mile') {
//     const conversionFactor = 1.609344;
//     const miles = kilometers / conversionFactor;
//     kilometers = miles
//   }

//   kilometers = Number(kilometers.toFixed(1))
//   kilometers = kilometers.toLocaleString();

//   if (withUnit) {
//     const unitStr = getDeviceDistanceUnit(unit)
//     kilometers = `${kilometers} ${unitStr}`
//   } else {
//     kilometers = `${kilometers}`
//   }
//   return kilometers
// }

export const getSpeedFromMiles = (miles, unit = 'km', withUnit = true) => { //unit: mile, km
  // if(empty(unit)) {
  //   unit = 'mile'
  // }
  if (is_null(miles)) {
    miles = 0
  }
  miles = Number(miles)
  if (unit === 'km') {
    const conversionFactor = 1.609344;
    const kilometers = miles * conversionFactor;
    miles = kilometers
  }

  miles = Number(miles.toFixed(1))
  miles = miles.toLocaleString();

  if (withUnit) {
    const unitStr = getDeviceSpeedUnit(unit)
    miles = `${miles} ${unitStr}`
  } else {
    miles = `${miles}`
  }
  return miles
}

export const priceFormat = (val, currency = '') => {
  if (empty(val)) val = 0
  const price = Number(val)
  let priceStr = price.toFixed(2)
  if (currency) {
    priceStr = `${currency}${priceStr}`
  }
  return priceStr
}

export const isMobile = () => {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) || window.matchMedia("(max-width: 768px)").matches;
}

export const curl_post = (path, params, method = 'post', target = '') => {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form');
  if (target !== "") { // _blank or _self
    form.target = target;
  }
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
  form.remove()
}