import React, { useState, useEffect, useRef } from 'react';
import {
  Grid, FormControlLabel, Switch, IconButton, makeStyles, Paper, Slider, Toolbar, Tooltip, Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Map from '../map/Map';
import ReplayPathMap from '../map/ReplayPathMap';
import PositionsMap from '../map/PositionsMap';
import { formatPosition } from '../common/formatter';
import ReportFilter from './ReportFilter';
import { useTranslation } from '../LocalizationProvider';
import SelectField from '../form/SelectField';
import { api_call, console_log, getSpeedFromMiles, isGpsMT2VProtocolDevice, isMobile } from '../helpers/untils';
import PaypalCreditLogsModal from '../settings/PaypalCreditLogsModal';
import { ButtonBase, Stack } from '@mui/material';
import { API_BASE_URL, DEVICE_TYPE } from '../config/constant';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    ...theme.typography.title,
  },
  sidebar: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reportFilterContainer: {
    flex: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
  },
  sliderContainer: {
    padding: theme.spacing(2),
  },
}));

const TimeLabel = ({ children, open, value }) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

const ReplayPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const timerRef = useRef();
  const { id } = useParams();
  //console_log("device id::::", id)

  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);
  const [accVal, setAccVal] = useState(2)
  const [showStopSign, setShowStopSign] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState(id);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [expanded, setExpanded] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const user = useSelector((state) => state.session.user);
  const device = useSelector((state) => selectedDeviceId ? state.devices.items[selectedDeviceId] : null);

  const deviceName = useSelector((state) => {
    if (selectedDeviceId) {
      const device = state.devices.items[selectedDeviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });

  useEffect(() => {
    if (isPlaying && positions.length > 0) {
      let interval;
      switch (playbackSpeed) {
        case 5:
          interval = 200;
          break;
        case 10:
          interval = 100;
          break;
        case 1:
        default:
          interval = 1000;
          break;
      }
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, interval);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isPlaying, positions]);

  useEffect(() => {
    setShowStopSign(false)
    if (index && index >= (positions.length - 1)) {
      clearInterval(timerRef.current);
      setIsPlaying(false);
    }
    if (accVal == 1 && positions[index]?.acc == 0) {
      setShowStopSign(true)
    }
    setAccVal(positions[index]?.acc)
  }, [index]);

  const submitParams = useRef(null)

  const handleSubmit = async (deviceId, from, to, _, headers) => {
    submitParams.current = {
      deviceId: deviceId,
      from: from,
      to: to
    }

    setSelectedDeviceId(deviceId);
    var query;
    if (!user.id && !!user.shareUrl) {
      const shareUrl = user.shareUrl;
      query = new URLSearchParams({ deviceId, from, to, shareUrl });
    }
    else {
      query = new URLSearchParams({ deviceId, from, to });
    }
    const response = await api_call(`/api/positions?${query.toString()}`, { headers });
    if (response.ok) {
      let positions_data = await response.json()
      setIndex(0);
      setPositions(positions_data);
      setExpanded(false);
    }
  }

  /////////////////////////////////////////start paypal credit logs modal//////////////////////////////////////////
  const [chargeRequiredNum, setChargeRequiredNum] = useState(0)
  const showPaypalCreditLogsPage = (item, chargeRequired = 0) => {
    //dispatch(layoutActions.closeDeviceListMenu());
    setDeviceItem(item)
    setPaypalCreditModalOpen(true)
    setChargeRequiredNum(chargeRequired)
  }
  const [paypalCreditModalOpen, setPaypalCreditModalOpen] = useState(false);
  const [deviceItem, setDeviceItem] = useState({});
  /////////////////////////////////////////end paypal credit logs modal////////////////////////////////////////////////////

  const exportCsv = () => {
    console.log(`exportCsv:::`, submitParams.current)
    const params = submitParams.current
    handleExportCsv(params.deviceId, params.from, params.to)
  }

  const handleExportCsv = (deviceId, from, to) => {
    var query;
    if (!user.id && !!user.shareUrl) {
      const shareUrl = user.shareUrl;
      query = new URLSearchParams({ deviceId, from, to, shareUrl });
    }
    else {
      query = new URLSearchParams({ deviceId, from, to });
    }

    const exportUrl = `${API_BASE_URL}/api/export-positions?${query.toString()}`
    console_log(`exportUrl::::`, exportUrl)
    window.location.href = exportUrl
  }

  const onClickNav = () => {
    if (positions.length > 0) {
      const position = positions[index]
      if (position) {
        window.open(`https://www.google.com/maps/dir/your+location/${position.latitude},${position.longitude}/@${position.latitude},${position.longitude},17z`, '_blank');
      }
    }
  }

  return (
    <div className={classes.root}>
      <Map>
        <ReplayPathMap positions={positions} />
        {index < positions.length && (
          <PositionsMap
            showStopSign={showStopSign}
            positions={[positions[index]]}
            pageType="replay"
            showPaypalCreditLogsPage={showPaypalCreditLogsPage}
          />
        )}
      </Map>
      <div className={classes.sidebar}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Paper elevation={3} square>
              <Toolbar disableGutters>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton onClick={
                      () => {
                        var fromMobile = localStorage.getItem("fromMobile");
                        if (fromMobile && isMobile()) {
                          const status = 'success';
                          window.location.href = 'moovetrax://return?status=' + status;
                        } else {
                          if (user.id) history.push('/main'); else history.goBack();
                        }
                      }
                    }
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.replay} color="primary">
                      {t('reportReplay')}
                    </Typography>
                  </Grid>
                  {!expanded && (
                    <Grid item>
                      <IconButton onClick={() => setExpanded(true)}>
                        <SettingsIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Toolbar>
            </Paper>
          </Grid>
          <Grid item>
            {!expanded ? (
              <Paper className={classes.sliderContainer}>
                <Grid container direction="column" alignItems="center" spacing={1}>
                  {
                    (positions.length > 0) ? (
                      <>
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Stack direction={`row`} alignItems={`center`} justifyContent={`flex-start`} style={{ width: '100%' }} spacing={2}>
                            <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} style={{ width: '100%', flex: 1 }} spacing={1}>
                              <span className={classes.title}>{deviceName}</span>
                              <Slider
                                max={positions.length - 1}
                                step={null}
                                marks={positions.map((_, index) => ({ value: index }))}
                                value={index}
                                onChange={(_, index) => setIndex(index)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(i) => (i < positions.length ? formatPosition(positions[i], 'deviceTime') : '')}
                                ValueLabelComponent={TimeLabel}
                              />
                            </Stack>

                            <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={1}>
                              <ButtonBase onClick={() => onClickNav()} disableRipple={true}>
                                <span style={{ cursor: 'pointer', color: '#1366ff' }}>NAV</span>
                              </ButtonBase>
                              <IconButton onClick={() => exportCsv()} title="Export">
                                <FileDownloadIcon />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item container justifyContent="space-between" alignItems="center">
                          <Grid item xs={2}>{`${index}/${positions.length - 1}`}</Grid>
                          <Grid item xs={2}>{`Playback: ${playbackSpeed}X`}</Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => setIndex((index) => index - 1)} disabled={isPlaying}>
                              <FastRewindIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => {
                              if (index < (positions.length - 1)) setIsPlaying(!isPlaying)
                            }}>
                              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => setIndex((index) => index + 1)} disabled={isPlaying}>
                              <FastForwardIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>{formatPosition(positions[index], 'deviceTime')}</Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between" alignItems="center" direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <div style={{ textAlign: 'left' }}>
                              {
                                (positions[index]?.speed) ? (
                                  <>{getSpeedFromMiles(positions[index].speed, device?.distance_unit)}</>
                                ) : (
                                  <>{getSpeedFromMiles(0, device?.distance_unit)}</>
                                )
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div style={{ textAlign: 'center' }}>
                              {
                                (positions[index]?.signal) ? (
                                  <>{"Signal: " + (Number(positions[index].signal) / 31 * 100).toFixed(0) + "%"}</>
                                ) : (
                                  <>{"Signal: 0%"}</>
                                )
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            {
                              (device?.deviceType === DEVICE_TYPE.TESLA || device?.deviceType === DEVICE_TYPE.SMARTCAR) ? (
                                <>
                                  <div style={{ textAlign: 'right' }}>
                                    {
                                      (positions[index]?.mt2v_dc_volt) ? (
                                        <>{`Bat: ${Number(positions[index]?.mt2v_dc_volt)} %`}</>
                                      ) : (
                                        <>{"Bat: 0 %"}</>
                                      )
                                    }
                                  </div>
                                </>
                              ) : (isGpsMT2VProtocolDevice(device?.uniqueId)) ? (
                                <div style={{ textAlign: 'right' }}>
                                  {
                                    (positions[index]?.mt2v_dc_volt) ? (
                                      <>{`Bat: ${positions[index]?.mt2v_dc_volt} V`}</>
                                    ) : (
                                      <>{"Bat: 0 V"}</>
                                    )
                                  }
                                </div>
                              ) : (
                                <></>
                              )
                            }
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item className='mt-3'>&#8211; No playback data found &#8211;</Grid>
                      </>
                    )
                  }
                </Grid>
              </Paper>
            ) : (
              <Paper elevation={3} className={classes.reportFilterContainer} square>
                <ReportFilter handleSubmit={handleSubmit} fullScreen showOnly selectedDeviceId={selectedDeviceId}>
                  <SelectField
                    margin="normal"
                    value={playbackSpeed + "X"}
                    emptyValue={null}
                    onChange={(event) => setPlaybackSpeed(() => {
                      switch (event.target.value) {
                        case '5X':
                          return 5;
                        case '10X':
                          return 10;
                        case '1X':
                        default:
                          return 1;
                      }
                    })}
                    data={[{ id: '1X', name: '1X' }, { id: '5X', name: '5X' }, { id: '10X', name: '10X' }]}
                    label='Playback Speed'
                    variant="filled"
                  />
                  <Grid item xs={6}>
                    <FormControlLabel
                      classes={{ root: classes.formControlLabel }}
                      control={(
                        <Switch
                          checked={isPlaying}
                          onChange={(e) => setIsPlaying(e.target.checked)}
                          color="primary"
                          edge="start"
                        />
                      )}
                      label="AutoPlay"
                      labelPlacement="start"
                    />
                  </Grid>
                </ReportFilter>
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>

      <>
        <PaypalCreditLogsModal
          open={paypalCreditModalOpen}
          setOpen={setPaypalCreditModalOpen}
          chargeRequiredNum={chargeRequiredNum}
          item={deviceItem}
          setItem={setDeviceItem}
        />
      </>
    </div>
  );
};

export default ReplayPage;
