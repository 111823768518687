import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles, Divider
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';


import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, getLocalTimezone, getUnixTimestampFromTimezone, get_utc_timestamp, get_utc_timestamp_ms, trimPhone } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';
import TollListModal from './TollListModal';
import TollSearchListTable from './TollSearchListTable';
import MaintListTable from './MaintListTable';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  // maxWidth: '1024px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const PAYMENT_METHOD_LIST = [
  'Transponder', //tagCost
  'Cash', //cashCost
  'License', //licensePlateCost
  'Most Expensive',
  'Least Expensive'
]

const MaintSettingModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem, history, onClickPL } = props;
  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">Maintenance Setting</Typography>
          <Box sx={{ paddingTop: 16 }}>
            <div style={{ maxHeight: 'calc(100vh - 255px)', overflowY: 'auto' }}>
              <MaintListTable
                open={open}
                setOpen={setOpen}
                deviceId={deviceItem?.id}
                deviceItem={deviceItem}
                onClickPL={() => {
                  setOpen(false)
                  onClickPL()
                }}
              />
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MaintSettingModal;
